<template>
  <div class="joinWrap">
    <div class="tabShow">
      <div class="titleWrap">
        <div class="txt">
          <div class="small">{{ joinList[index].small }}</div>
          <div class="line"></div>
          <div class="title">{{ joinList[index].title }}</div>
        </div>
        <div class="wx">
          <img :src="`img/wxicon.png`" alt="" />
          <div class="hover">
            <img :src="`img/wxHove.png`" alt="" />
          </div>
        </div>
      </div>
      <div class="content">
        <div class="tips" v-html="joinList[index].tips"></div>
        <div class="requirement">
          <div class="tit">职位要求</div>
          <div class="reqTxt" v-html="joinList[index].requirement"></div>
        </div>
        <div class="duty">
          <div class="tit">岗位职责</div>
          <div class="reqTxt" v-html="joinList[index].duty"></div>
        </div>
      </div>
      <div class="btnGroup">
        <div class="prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
        <div class="next" @click="next">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="pages">
          <div class="current">0{{ index + 1 }}</div>
          <div class="total">/ 0{{ total }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      joinList: [
        {
          title: "影视后期制作",
          small: "Television Late",
          tips:
            "你敢于创新，对事物保持好奇心，追求细节<br>但不偏执。或许当idea上线，你会有一丝成<br>就感。",
          requirement: `1、拥有良好的后期导演思维能力；2、了解商业
                        短视频（TVC、宣传片、病毒视频、微电影广告）
                        的制作流程；3、熟练pr、AE等后期软件；4、能
                        够熟练C4D、MAYA等三维软件者优先；5、拥有
                        一定得商业短视频后期剪辑经验；8、具备良好的
                        沟通能力，工作敬业、积极主动和团队合作精神。`,
          duty: `独立完成视频的设计。`,
        },
        {
          title: "影视后期制作2",
          small: "Television Late2",
          tips:
            "你敢于创新，对事物保持好奇心，追求细节<br>但不偏执。或许当idea上线，你会有一丝成<br>就感。2",
          requirement: `1、拥有良好的后期导演思维能力；2、了解商业<br>
                        短视频（TVC、宣传片、病毒视频、微电影广告）<br>
                        的制作流程；3、熟练pr、AE等后期软件；4、能<br>
                        够熟练C4D、MAYA等三维软件者优先；`,
          duty: `独立完成视频的设计。`,
        },
        {
          title: "影视后期制作3",
          small: "Television Late3",
          tips:
            "你敢于创新，对事物保持好奇心，追求细节<br>但不偏执。或许当idea上线，你会有一丝成<br>就感。3",
          requirement: `1、拥有良好的后期导演思维能力；2、了解商业<br>
                        短视频（TVC、宣传片、病毒视频、微电影广告）<br>
                        的制作流程；3、熟练pr、AE等后期软件；`,
          duty: `独立完成视频的设计。`,
        },
        {
          title: "影视后期制作4",
          small: "Television Late4",
          tips:
            "你敢于创新，对事物保持好奇心，追求细节<br>但不偏执。或许当idea上线，你会有一丝成<br>就感。4",
          requirement: `3、熟练pr、AE等后期软件；4、能<br>
                        够熟练C4D、MAYA等三维软件者优先；5、拥有<br>
                        一定得商业短视频后期剪辑经验；8、具备良好的<br>
                        沟通能力，工作敬业、积极主动和团队合作精神。`,
          duty: `独立完成视频的设计。4`,
        },
      ],
    };
  },
  computed: {
    total() {
      return this.joinList.length;
    },
  },
  methods: {
    prev() {
      if (this.index > 0) {
        this.index--;
      } else {
        this.index = 0;
      }
    },
    next() {
      if (this.index < this.total - 1) {
        this.index++;
      } else {
        this.index = this.total - 1;
      }
    },
  },
};
</script>

<style lang="stylus" scoped  rel="stylesheet/stylus">
.joinWrap {
  background: #ffffff url('../../public/img/joinBg.png') no-repeat center top;
  background-size: auto 872px;
  height: 872px;
  padding-top: 123px;
  box-sizing: border-box;
}

.tabShow {
  width: 1240px;
  margin: 0 auto;
  height: 749px;
  position: relative;
  background-color: #FAFAFA;
  display: flex;
  box-sizing: border-box;
  padding: 55px 55px 55px 91px;

  .titleWrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .txt {
      .small {
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0F0F0F;
        line-height: 26px;
      }

      .title {
        font-size: 31px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #343434;
        line-height: 26px;
      }

      .line {
        width: 43px;
        height: 3px;
        background: #D1D1D1;
        margin: 25px 0 55px;
      }
    }

    .wx {
      position: relative;
      cursor: pointer;

      img {
        width: 57px;
        height: 46px;
      }

      .hover {
        width: 164px;
        height: 173px;
        display: none;
        position: absolute;
        bottom: 55px;
        left: -54px;

        img {
          width: 164px;
          height: 173px;
        }
      }

      &:hover {
        .hover {
          display: block;
        }
      }
    }
  }

  .content {
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tips {
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #484848;
      line-height: 40px;
    }

    .tit {
      font-size: 23px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #484848;
      line-height: 40px;
    }

    .reqTxt {
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #484848;
      line-height: 29px;
    }
    .duty{
      padding-bottom 50px
    }
  }

  .btnGroup {
    width: 340px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .pages {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 100px;
      height: 40px;

      .current {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0F0F0F;
      }

      .total {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #8C8C8C;
      }
    }

    .prev {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -9px;
      right: 80px;
      width: 57px;
      height: 57px;
      background-color: #333333;
      border-radius: 100%;
      color: #ffffff;
      font-size: 36px;
      cursor: pointer;
    }

    .next {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -9px;
      right: 10px;
      width: 57px;
      height: 57px;
      background-color: #333333;
      border-radius: 100%;
      color: #ffffff;
      font-size: 36px;
      cursor: pointer;
    }
  }
}
</style>